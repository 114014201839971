import React from "react";
import classNames from 'classnames';

import { useIntl } from 'gatsby-plugin-intl'

import '../styles/index.css';
import styles from '../styles/service.module.css';

import HeaderImage from '../components/imageHeader';
import BoxService from "../components/boxService";

import Layout from "../components/layout";

const Servico = () => {

    const intl = useIntl()

    const isEnglish = intl.locale === 'en'

    return (
        <Layout titlePage={isEnglish ? "Services" : "Serviços"}>
            <HeaderImage title={isEnglish ? "Services" : "Serviços"} />

            <div className={classNames("container", styles.serviceContainer)}>
                <main style={{ marginTop: 200}}>
                    <BoxService />
                </main>
            </div>
        </Layout>
    )
}


export default Servico
